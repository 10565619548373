<template>
  <div class="page_wrapper">
    <div class="fix_head">
      <a href="#" @click="$router.back()">&lt; 返回</a>
      保单详情
    </div>
    <div class="detail_box">
      <div class="cell_line" v-if="serviceInfo.beneficiary">
        <h2 class="h2">受益人信息</h2>
        <div class="line">
          <div class="name">姓名</div>
          <div class="value_box" id="name"> {{ serviceInfo.beneficiary.customerName }}</div>
        </div>
        <div class="line">
          <div class="name">证件号</div>
          <div class="value_box" id="cardNo">{{ serviceInfo.beneficiary.certificateNo }}</div>
        </div>
        <div class="line">
          <div class="name">车架号</div>
          <div class="value_box" id="standNo">{{ serviceInfo.beneficiary.vin }}</div>
        </div>
        <div class="line">
          <div class="name">发动机号</div>
          <div class="value_box" id="engine">{{ serviceInfo.beneficiary.engineNo || '--' }}</div>
        </div>
        <div class="line">
          <div class="name">厂牌型号</div>
          <div class="value_box" id="brandName"> {{ serviceInfo.beneficiary.vehicleBrandName || '--' }} {{ serviceInfo.beneficiary.vehicleSeriesName || '--' }} {{ serviceInfo.beneficiary.vehicleModelName || '--' }}</div>
        </div>
      </div>
    </div>
    <!--  v-for="item in serviceInfo.insuranceDetails" :key="item.orgInsuranceNo" -->
    <div class="cell_line">
      <h2 class="h2 i_01">产品信息</h2>
      <div class="line">
        <div class="name">保单编号</div>
        <div class="value_box" id="fullInsuranceNo">{{ insuranceDetails.orgInsuranceNo || '--' }}</div>
        <van-button type="default" class='copy' v-if="insuranceDetails.orgInsuranceNo" v-clipboard:copy="insuranceDetails.orgInsuranceNo" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</van-button>
      </div>
      <div class="line" v-if="insuranceDetails.serialNumber">
        <div class="name">流水号</div>
        <div class="value_box" id="fullInsuranceNo">{{ insuranceDetails.serialNumber }}</div>
        <van-button class='copy' v-if="insuranceDetails.serialNumber" v-clipboard:copy="insuranceDetails.serialNumber" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</van-button>
      </div>
      <div class="line">
        <div class="name">保险期限</div>
        <div class="value_box" id="effectDate">{{ insuranceDetails.effectiveTime }} 0时至 {{ insuranceDetails.terminationTime }}24时止 </div>
      </div>
      <div class="line">
        <div class="name">产品名称</div>
        <div class="value_box">{{ insuranceDetails.productName || '--' }}</div>
      </div>
    </div>
    <div class="official" v-if="footerInfo.type=='image'">
      <div class="official-code">
        <img :src="footerInfo.content" :alt="footerInfo.label" class="picc">
      </div>
      <div class="official-remak">
        <span>{{footerInfo.label}} </span>
      </div>
    </div>
    <div v-if="footerInfo.type=='telphone'" class="official-qh">
      <a @click="call(footerInfo.content)">
        <span class="color9">{{footerInfo.label }}{{footerInfo.content}}</span>
      </a>
    </div>
    <div v-if="footerInfo.type=='website'" class="official-qh">
      <a :href="footerInfo.content">
        <span class="color9">{{footerInfo.label||footerInfo.content}}</span>
      </a>
    </div>
  </div>
</template>
<script>
  import { getDetail } from '@/api/verification'
  export default {
    name: 'ServiceDetail',
    data() {
      return {
        keyword: '',
        isPicc: true,
        serviceInfo: {},
        footerInfo: {}, // { "type": "telphone", "label": "保险公司验真咨询电话:", "content": "95500" }
        // footerInfo: {"type":"website","label":"","content":"https://www.qhins.com/webins/welcome.jsp"},
        // footerInfo: {"type":"qr","label":"扫码关注人保公众号，复制保单号前往验真","content":"https://m.juruiyun.com/img/picc.0002e730.png"},
        index: 0,
        insuranceDetails: {}
      }
    },
    async created() {
      this.keyword = this.$route.query.keyword
      // this.index =  this.$route.query.index
      // this.isPicc = this.$route.query.type === 'picc'
      if (!this.keyword) {
        this.$toast.fail('查询保单号为空')
        return
      }
      const resp = await getDetail({ keyword: this.keyword })
      if (!resp.success) {
        this.$toast.fail(resp.respMag)
        return
      }
      this.serviceInfo = resp.content
      this.footerInfo = this.serviceInfo.underwriter || {}
      this.insuranceDetails = this.serviceInfo.insuranceDetails[0]
    },
    methods: {
      onCopy(e) {
        // console.log(e);
        this.$toast(`${e.text} 已复制到剪切板`)
      },
      // 复制失败
      onError(e) {
        console.log(e);
      },
      call(phone) {
        window.location.href = `tel:${phone}`;
      }
    }
  }
</script>
<style scoped>
  * {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  body,
  html {
    background: #f2f2f2;
  }

  .cell_group {
    padding: 10px;
  }

  .official {
    padding: 10px;
    display: flex;
    margin-bottom: 30px;
  }

  .official .picc {
    width: 120px;
  }

  .page_wrapper {
    padding-top: 40px;
  }

  .fix_head {
    background: #ED5D43;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    padding: 0 10px;
    text-align: center;
  }

  .fix_head a {
    color: #fff;
    text-decoration: none;
    position: absolute;
    height: 100%;
    width: 60px;
    left: 0px;
  }

  .cell_line {
    background: #fff;
    margin: 10px 0;
    padding: 0 10px;
  }

  .cell_line .h2 {
    padding: 10px;
    color: #ED5D43;
    font-weight: normal;
    border-bottom: 1px solid #f2f2f2;
    background: url(../../assets/official-account/i_02.png) no-repeat left center;
    background-size: 20px auto;
    padding-left: 24px;
  }

  .cell_line .i_01 {
    background: url(../../assets/official-account/i_01.png) no-repeat left center;
    background-size: 20px auto;
    padding-left: 24px;
  }

  .line {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px;
  }

  .line .name {
    width: 90px;
  }

  .line .value_box {
    flex: 1;
    text-align: right;
  }

  .official .official-code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
  }

  .official .official-remak {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copy {
    border: none;
    padding: 2px 8px;
    margin-left: 5px;
    background-color: #999;
    color: #fff;
    width: 50px;
    line-height: 22px;
    height: 22px;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
  }

  .official-qh {
    padding: 40px 20px;
    text-align: center;
  }

  .color9 {
    color: #999;
  }
</style>
